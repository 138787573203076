
import { defineComponent, inject, onMounted, reactive, Ref, ref, watch } from "vue";

//引入图标
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faYenSign, faGamepad, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUser);
library.add(faYenSign);
library.add(faGamepad);
library.add(faCoins);

//图表引入
import { Column, Line, Area } from "@antv/g2plot";
import http from "@/common/http";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  setup() {
    //渲染充值走势
    const renderPayCharts = () => {
      const data = [
        {
          type: "家具家电",
          sales: 38,
        },
        {
          type: "粮油副食",
          sales: 52,
        },
        {
          type: "生鲜水果",
          sales: 61,
        },
        {
          type: "美容洗护",
          sales: 145,
        },
        {
          type: "母婴用品",
          sales: 48,
        },
        {
          type: "进口食品",
          sales: 38,
        },
        {
          type: "食品饮料",
          sales: 38,
        },
        {
          type: "家庭清洁",
          sales: 38,
        },
      ];
      const columnPlot = new Column("home-charts-pay", {
        data,
        xField: "type",
        yField: "sales",
        height: 200,
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: "#FFFFFF",
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          type: {
            alias: "类别",
          },
          sales: {
            alias: "销售额",
          },
        },
      });

      columnPlot.render();
    };

    const userinfo = ref<any>({}); //用户信息

    const gamePayObj = ref<any>({
      monthAmount: 0,
      todayAmount: 0,
    }); //充值数据

    const usUserCountObj = ref<any>({
      totalCount: 0,
      monthCount: 0,
      todayCount: 0,
    }); //玩家数量统计
    const gamePeakObj = ref<any>({
      maxPeak: 0,
      monthMaxPeak: 0,
      TodayMaxPeak: 0,
    }); //游戏峰值统计

    const playCountObj = ref<any>({
      monthCount: 0,
      todayCount: 0,
    }); //游戏人数

    const beansObj = ref<any>({
      totalAmount: 0,
      totalUserCount: 0,
      monthTotalAmount: 0,
      monthIncAmount: 0,
      monthDecAmount: 0,
      todayTotalAmount: 0,
      todayIncAmount: 0,
      todayDecAmount: 0,
    }); //钻石汇总数据

    const goldObj = ref<any>({
      totalAmount: 0,
      totalUserCount: 0,
      monthTotalAmount: 0,
      monthIncAmount: 0,
      monthDecAmount: 0,
      todayTotalAmount: 0,
      todayIncAmount: 0,
      todayDecAmount: 0,
    }); //金币汇总数据

    const onlineDataObj = ref<any>({
      nextDayOnlineUserCount: 0,
      day3OnlineUserCount: 0,
      day7OnlineUserCount: 0,
    });

    //日游戏人数
    const gameCountTabList = [
      {
        key: "1",
        tab: "日游戏人数汇总",
      },
      {
        key: "2",
        tab: "海南麻将私人场",
      },
      {
        key: "3",
        tab: "海南麻将俱乐部",
      },
      // {
      //   key: "4",
      //   tab: "海南麻将金币场",
      // },
    ];
    const activeGameCountKey = ref("1");
    const handleGameCountKeyChange = (key: any) => {
      activeGameCountKey.value = key;
      renderGamePlayCountChart();
    };

    //由socketio传递过来的数据
    const homeData: any = inject("homeData");
    const homeGameCountChartData: any = inject("homeGameCountChartData");
    //数据变更后重新渲染图表
    watch(homeGameCountChartData, val => {
      renderOnlineCharts(val);
    });
    //在线人数/游戏人数图表数据
    let gameCountChartPlot: Line | undefined;

    //按天统计的注册人数图表数据
    const dayRegCountChart = ref<any>([]);
    const monthRegCountChart = ref<any>([]);
    const dayRegSearchData = reactive({
      startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
      stType: 1, //1按天  2按月
    });
    //注册人数图表
    let regCountChartPlot: Area | undefined;

    //按天统计的游戏人数图表数据
    const dayPlayCountChartData = ref<any>([]);
    const dayPeakData = ref<any>([]);
    const dayPlayCountSearchData = reactive({
      startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
      stType: 1, //1按天  2按月
    });
    //注册人数图表
    let playCountChartPlot: Line | undefined;

    //各游戏人数
    const gamePlayCountChartData = ref<any>([]); //游戏人数数据
    const gamePlayCountSearchData = reactive({
      startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
    });
    //个游戏人数图表
    let gamePlayCountChartPlot: Line | undefined;

    //获取用户信息
    const getUserinfo = async () => {
      const { data } = await http.get("session/detail");
      userinfo.value = data.data;
    };

    //获取汇总统计信息
    const getStatistic = async () => {
      const { data } = await http.get("home/statistic");
      usUserCountObj.value = data.usUserCountObj;
      gamePeakObj.value = data.gamePeakObj;
      playCountObj.value = data.playCountObj;
      beansObj.value = data.beansObj;
      goldObj.value = data.goldObj;
      onlineDataObj.value = data.onlineDataObj;
      gamePayObj.value = data.gamePayObj;
    };

    //获取当前游戏人数
    const getCurrPlayCount = async () => {
      const { data } = await http.get("home/curr_play_count");
      homeData.value.currPlayCount = data.currPlayCount;
    };

    //获取在线人数图表数据
    const getGameCountChartData = async () => {
      const { data } = await http.get("home/game_count_chart_data");
      homeGameCountChartData.value = data.data;

      // //渲染图表
      // renderOnlineCharts(homeGameCountChartData.value);
    };
    //渲染在线人数
    const renderOnlineCharts = (data: any) => {
      const rdata: any = [];
      data.forEach((item: any) => {
        rdata.push({
          time: item.time,
          name: "在线人数",
          value: item.onlineCount,
        });
        rdata.push({
          time: item.time,
          name: "游戏人数",
          value: item.playCount,
        });
      });

      if (gameCountChartPlot) {
        gameCountChartPlot?.changeData(rdata);
      } else {
        gameCountChartPlot = new Line("home-charts-online", {
          data: rdata,
          height: 200,
          xField: "time",
          yField: "value",
          seriesField: "name",
          // yAxis: {
          //   label: {
          //     formatter: (v: any) => `${(v / 10e8).toFixed(1)} B`,
          //   },
          // },
          legend: {
            position: "top",
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: "path-in",
              duration: 1500,
            },
          },
        });

        gameCountChartPlot.render();
      }
    };

    //获取新增玩家图表数据
    const getRegCountChartData = async () => {
      const { data } = await http.get("home/reg_chart_data", dayRegSearchData);
      dayRegCountChart.value = data.dayData;
      monthRegCountChart.value = data.monthData;

      renderNewPlayerCharts();
    };
    //渲染新增玩家图表
    const renderNewPlayerCharts = () => {
      let data = dayRegCountChart.value;
      if (dayRegSearchData.stType == 2) {
        //按月
        data = monthRegCountChart.value;
      }
      if (regCountChartPlot) {
        regCountChartPlot?.changeData(data);
      } else {
        regCountChartPlot = new Area("home-charts-player", {
          data: data,
          xField: "time",
          yField: "count",
          height: 200,
          // label: {
          //   // 可手动配置 label 数据标签位置
          //   position: "middle", // 'top', 'bottom', 'middle',
          //   // 配置样式
          //   style: {
          //     fill: "#FFFFFF",
          //     opacity: 0.6,
          //   },
          // },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          meta: {
            count: {
              alias: "新增人数",
            },
          },
        });

        regCountChartPlot.render();
      }
    };

    //获取宽度
    const mediaType = inject<Ref<string>>("mediaType");
    const getCardWidth = () => {
      if (mediaType!.value == "lg") {
        return "33.33%";
      } else {
        return "100%";
      }
    };

    //获取在线人数表
    const getPlayCountChartData = async () => {
      const { data } = await http.get("home/play_count_chart_data", dayPlayCountSearchData);
      dayPlayCountChartData.value = data.dayPlayCountData;
      dayPeakData.value = data.dayPeakData;

      renderPlayCountCharts();
    };
    //渲染游戏人数图表
    const renderPlayCountCharts = () => {
      // let data = dayPlayCountChartData.value;
      // if (dayRegSearchData.stType == 2) {
      //   //按月
      //   data = monthRegCountChart.value;
      // }

      //格式化数据
      const dateList: any = [];
      const data: any = [];
      //先找到所有的时间
      dayPlayCountChartData.value.forEach((item: any) => {
        dateList.push(item.time);
      });
      dayPeakData.value.forEach((item: any) => {
        const index = dateList.findIndex((itm: any) => {
          return itm == item.time;
        });
        if (index === -1) {
          dateList.push(item.time);
        }
      });
      //按时间归类数据
      dateList.forEach((item: any) => {
        let pcCount = 0;
        const pcItem = dayPlayCountChartData.value.find((itm: any) => {
          return itm.time == item;
        });
        if (pcItem) {
          pcCount = pcItem.count;
        }
        data.push({
          time: item,
          count: pcCount,
          name: "游戏人数",
        });

        let pkCount = 0;
        const pkItem = dayPeakData.value.find((itm: any) => {
          return itm.time == item;
        });
        if (pkItem) {
          pkCount = pkItem.count;
        }
        data.push({
          time: item,
          count: pkCount,
          name: "在线峰值",
        });
      });

      if (playCountChartPlot) {
        playCountChartPlot?.changeData(data);
      } else {
        playCountChartPlot = new Line("home-charts-play-count", {
          data: data,
          xField: "time",
          yField: "count",
          seriesField: "name",
          height: 200,
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          legend: {
            position: "top",
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: "path-in",
              duration: 1500,
            },
          },
        });

        playCountChartPlot.render();
      }
    };

    //获取游戏人数
    const getGamePlayCountChartData = async () => {
      const { data } = await http.get("home/play_game_play_count_chart_data", gamePlayCountSearchData);
      gamePlayCountChartData.value = data.data;

      renderGamePlayCountChart();
    };
    const renderGamePlayCountChart = () => {
      const rdata: any = [];
      let timeKey = "stDate";
      gamePlayCountChartData.value.forEach((item: any) => {
        const time = item[timeKey].replaceAll("-", "/");
        if (activeGameCountKey.value == "1") {
          rdata.push({
            time: time,
            name: "总游戏人数",
            value: item.hnmjTotalUserCount,
          });
          rdata.push({
            time: time,
            name: "海南麻将私人场",
            value: item.hnmjUserCount,
          });
          rdata.push({
            time: time,
            name: "海南麻将俱乐部",
            value: item.clubHnmjUserCount,
          });
          // rdata.push({
          //   time: time,
          //   name: "海南麻将金币场",
          //   value: item.hnmjGoldUserCount,
          // });
        } else if (activeGameCountKey.value == "2") {
          rdata.push({
            time: time,
            name: "二人",
            value: item.hnmj2UserCount,
          });
          rdata.push({
            time: time,
            name: "三人",
            value: item.hnmj3UserCount,
          });
          rdata.push({
            time: time,
            name: "四人",
            value: item.hnmj4UserCount,
          });
        } else if (activeGameCountKey.value == "3") {
          rdata.push({
            time: time,
            name: "二人",
            value: item.clubHnmj2UserCount,
          });
          rdata.push({
            time: time,
            name: "三人",
            value: item.clubHnmj3UserCount,
          });
          rdata.push({
            time: time,
            name: "四人",
            value: item.clubHnmj4UserCount,
          });
        }
        // else if (activeGameCountKey.value == "4") {
        //   rdata.push({
        //     time: time,
        //     name: "二人",
        //     value: item.clubHnmj2UserCount,
        //   });
        //   rdata.push({
        //     time: time,
        //     name: "三人",
        //     value: item.clubHnmj3UserCount,
        //   });
        //   rdata.push({
        //     time: time,
        //     name: "四人",
        //     value: item.clubHnmj4UserCount,
        //   });
        // }
      });

      if (gamePlayCountChartPlot) {
        gamePlayCountChartPlot?.changeData(rdata);
      } else {
        gamePlayCountChartPlot = new Line("home-charts-gmcount-main", {
          data: rdata,
          height: 200,
          xField: "time",
          yField: "value",
          seriesField: "name",
          // yAxis: {
          //   label: {
          //     formatter: (v: any) => `${(v / 10e8).toFixed(1)} B`,
          //   },
          // },
          legend: {
            position: "top",
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: "path-in",
              duration: 1500,
            },
          },
        });

        gamePlayCountChartPlot.render();
      }
    };

    onMounted(() => {
      renderPayCharts();

      getUserinfo();
      getStatistic();
      getCurrPlayCount();
      getGameCountChartData();
      getRegCountChartData();
      getPlayCountChartData();
      getGamePlayCountChartData();
    });

    return {
      userinfo,
      gamePayObj,
      usUserCountObj,
      gamePeakObj,
      playCountObj,
      homeData,
      dayRegSearchData,
      dayPlayCountSearchData,
      beansObj,
      goldObj,
      gameCountTabList,
      activeGameCountKey,
      gamePlayCountSearchData,
      onlineDataObj,

      getRegCountChartData,
      renderNewPlayerCharts,
      getPlayCountChartData,
      handleGameCountKeyChange,
      getGamePlayCountChartData,
      getCardWidth,
    };
  },
});
