<template>
  <div>
    <div class="l-home-header">
      <div class="l-flex" style="margin-top: 15px">
        <div>
          <a-avatar :size="60">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </div>
        <div class="l-flex l-flex-center" style="padding-left: 20px">
          <div>
            <div class="l-home-hello">欢迎您，{{ userinfo.name }}!</div>
            <div class="l-home-sec-text">{{ userinfo.roleName }} | 登录时间：{{ userinfo.loginTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="l-home-static-container">
      <a-card title="" style="margin-top: 15px">
        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div><font-awesome-icon icon="yen-sign" class="l-home-st-icon" :style="{ color: 'gold' }" /></div>
            <div class="l-flex-1">
              <a-statistic title="本月总充值" :value="gamePayObj.monthAmount" suffix="元" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="今日充值" :value="gamePayObj.todayAmount" suffix="元" />
            </div>
          </div>
        </a-card-grid>

        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div><font-awesome-icon icon="user" class="l-home-st-icon" :style="{ color: '#3B82F6' }" /></div>
            <div class="l-flex-1">
              <a-statistic title="玩家总数量" :value="usUserCountObj.totalCount" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="本月新增" :value="usUserCountObj.monthCount" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="今日新增" :value="usUserCountObj.todayCount" suffix="人" />
            </div>
          </div>
        </a-card-grid>

        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div><font-awesome-icon icon="user" class="l-home-st-icon" :style="{ color: '#3B82F6' }" /></div>
            <div class="l-flex-1">
              <a-statistic title="7日留存" :value="onlineDataObj.day7OnlineUserCount" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="3日留存" :value="onlineDataObj.day3OnlineUserCount" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="次日留存" :value="onlineDataObj.nextDayOnlineUserCount" suffix="人" />
            </div>
            <!-- <div class="l-flex-1">
              <a-statistic title="今日新增" :value="usUserCountObj.todayCount" suffix="人" />
            </div> -->
          </div>
        </a-card-grid>

        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div><RiseOutlined icon="think-peaks" class="l-home-st-icon" :style="{ color: '#22C55E' }" /></div>
            <div class="l-flex-1">
              <a-statistic title="最高峰值" :value="gamePeakObj.maxPeak" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="本月峰值" :value="gamePeakObj.monthMaxPeak" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="今日峰值" :value="gamePeakObj.TodayMaxPeak" suffix="人" />
            </div>
          </div>
        </a-card-grid>

        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div><font-awesome-icon icon="gamepad" class="l-home-st-icon" :style="{ color: '#F59E0B' }" /></div>
            <div class="l-flex-1">
              <a-statistic title="本月游戏人数" :value="playCountObj.monthCount" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="今日游戏人数" :value="playCountObj.todayCount" suffix="人" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="当前游戏人数" :value="homeData.currPlayCount" suffix="人" />
            </div>
          </div>
        </a-card-grid>
        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div>
              <SketchOutlined class="l-home-st-icon" :style="{ color: '#1890ff' }"></SketchOutlined>
            </div>
            <div class="l-flex-1">
              <a-statistic title="当前钻石持有" :value="beansObj.totalAmount" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="本月变化">
                <template #formatter>
                  <div class="l-home-static-ch-font">
                    <l-num class="l-bold" :value="beansObj.monthTotalAmount" />

                    (
                    <span>
                      <ArrowUpOutlined class="l-font-success" />
                      <l-num :value="beansObj.monthIncAmount"></l-num>
                    </span>
                    <span style="margin-left: 5px">
                      <ArrowDownOutlined class="l-font-danger" />
                      <l-num :value="beansObj.monthDecAmount"></l-num>
                    </span>

                    )
                  </div>
                </template>
              </a-statistic>
            </div>
            <div class="l-flex-1">
              <a-statistic title="今日变化">
                <template #formatter>
                  <div class="l-home-static-ch-font">
                    <l-num class="l-bold" :value="beansObj.todayTotalAmount" />

                    (
                    <span>
                      <ArrowUpOutlined class="l-font-success" />
                      <l-num :value="beansObj.todayIncAmount"></l-num>
                    </span>
                    <span style="margin-left: 5px">
                      <ArrowDownOutlined class="l-font-danger" />
                      <l-num :value="beansObj.todayDecAmount"></l-num>
                    </span>

                    )
                  </div>
                </template>
              </a-statistic>
            </div>
          </div>
        </a-card-grid>

        <a-card-grid style="text-align: center" :style="{ width: getCardWidth() }">
          <div class="l-home-static-box">
            <div>
              <div><font-awesome-icon icon="coins" class="l-home-st-icon" :style="{ color: 'gold' }" /></div>
            </div>
            <div class="l-flex-1">
              <a-statistic title="当前金币持有" :value="goldObj.totalAmount" />
            </div>
            <div class="l-flex-1">
              <a-statistic title="本月变化">
                <template #formatter>
                  <div class="l-home-static-ch-font">
                    <l-num class="l-bold" :value="goldObj.monthTotalAmount" />

                    (
                    <span>
                      <ArrowUpOutlined class="l-font-success" />
                      <l-num :value="goldObj.monthIncAmount"></l-num>
                    </span>
                    <span style="margin-left: 5px">
                      <ArrowDownOutlined class="l-font-danger" />
                      <l-num :value="goldObj.monthDecAmount"></l-num>
                    </span>

                    )
                  </div>
                </template>
              </a-statistic>
            </div>
            <div class="l-flex-1">
              <a-statistic title="今日变化">
                <template #formatter>
                  <div class="l-home-static-ch-font">
                    <l-num class="l-bold" :value="goldObj.todayTotalAmount" />

                    (
                    <span>
                      <ArrowUpOutlined class="l-font-success" />
                      <l-num :value="goldObj.todayIncAmount"></l-num>
                    </span>
                    <span style="margin-left: 5px">
                      <ArrowDownOutlined class="l-font-danger" />
                      <l-num :value="goldObj.todayDecAmount"></l-num>
                    </span>

                    )
                  </div>
                </template>
              </a-statistic>
            </div>
          </div>
        </a-card-grid>
      </a-card>

      <div style="margin-top: 15px"></div>
      <a-row :gutter="[15, 15]">
        <a-col :md="24" :lg="24" :sm="24" :xs="24">
          <a-card title="今日实时在线人数">
            <div id="home-charts-online" class="home-charts-container"></div>
          </a-card>
        </a-col>

        <a-col :lg="24" :xl="12" :sm="24" :xs="24">
          <a-card title="游戏人数/在线峰值">
            <a-form layout="inline">
              <!-- <a-form-item>
              <a-radio-group v-model:value="dayPlayCountSearchData.stType" button-style="solid" @change="renderNewPlayerCharts">
                <a-radio-button :value="1">按天显示</a-radio-button>
                <a-radio-button :value="2">按月显示</a-radio-button>
              </a-radio-group>
            </a-form-item> -->
              <a-form-item>
                <a-date-picker @change="getPlayCountChartData" v-model:value="dayPlayCountSearchData.startTime" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
              <a-form-item>~</a-form-item>
              <a-form-item>
                <a-date-picker @change="getPlayCountChartData" v-model:value="dayPlayCountSearchData.endTime" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
            </a-form>

            <div id="home-charts-play-count" class="home-charts-container"></div>
          </a-card>
        </a-col>

        <a-col :lg="24" :xl="12" :sm="24" :xs="24">
          <a-card title="新增玩家趋势图">
            <a-form layout="inline">
              <a-form-item>
                <a-radio-group v-model:value="dayRegSearchData.stType" button-style="solid" @change="renderNewPlayerCharts">
                  <a-radio-button :value="1">按天显示</a-radio-button>
                  <a-radio-button :value="2">按月显示</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item>
                <a-date-picker @change="getRegCountChartData" v-model:value="dayRegSearchData.startTime" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
              <a-form-item>~</a-form-item>
              <a-form-item>
                <a-date-picker @change="getRegCountChartData" v-model:value="dayRegSearchData.endTime" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
            </a-form>

            <div id="home-charts-player" class="home-charts-container"></div>
          </a-card>
        </a-col>

        <a-col :lg="24" :xl="12" :sm="24" :xs="24">
          <a-card title="充值趋势图">
            <a-form layout="inline">
              <a-form-item>
                <a-radio-group v-model:value="dayRegSearchData.stType" button-style="solid" @change="renderNewPlayerCharts">
                  <a-radio-button :value="1">按天显示</a-radio-button>
                  <a-radio-button :value="2">按月显示</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item>
                <a-date-picker @change="getRegCountChartData" v-model:value="dayRegSearchData.startTime" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
              <a-form-item>~</a-form-item>
              <a-form-item>
                <a-date-picker @change="getRegCountChartData" v-model:value="dayRegSearchData.endTime" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
            </a-form>
            <div id="home-charts-pay" class="home-charts-container"></div>
          </a-card>
        </a-col>

        <a-col :lg="24" :xl="12" :sm="24" :xs="24">
          <a-card title="" :tab-list="gameCountTabList" :active-tab-key="activeGameCountKey" @tabChange="handleGameCountKeyChange" style="width: 100%">
            <a-form layout="inline">
              <a-form-item>
                <a-date-picker @change="getGamePlayCountChartData" v-model:value="gamePlayCountSearchData.startTime" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
              <a-form-item>~</a-form-item>
              <a-form-item>
                <a-date-picker @change="getGamePlayCountChartData" v-model:value="gamePlayCountSearchData.endTime" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
              </a-form-item>
            </a-form>
            <div id="home-charts-gmcount-main" class="home-charts-container"></div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, reactive, Ref, ref, watch } from "vue";

//引入图标
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faYenSign, faGamepad, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUser);
library.add(faYenSign);
library.add(faGamepad);
library.add(faCoins);

//图表引入
import { Column, Line, Area } from "@antv/g2plot";
import http from "@/common/http";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  setup() {
    //渲染充值走势
    const renderPayCharts = () => {
      const data = [
        {
          type: "家具家电",
          sales: 38,
        },
        {
          type: "粮油副食",
          sales: 52,
        },
        {
          type: "生鲜水果",
          sales: 61,
        },
        {
          type: "美容洗护",
          sales: 145,
        },
        {
          type: "母婴用品",
          sales: 48,
        },
        {
          type: "进口食品",
          sales: 38,
        },
        {
          type: "食品饮料",
          sales: 38,
        },
        {
          type: "家庭清洁",
          sales: 38,
        },
      ];
      const columnPlot = new Column("home-charts-pay", {
        data,
        xField: "type",
        yField: "sales",
        height: 200,
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: "#FFFFFF",
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          type: {
            alias: "类别",
          },
          sales: {
            alias: "销售额",
          },
        },
      });

      columnPlot.render();
    };

    const userinfo = ref<any>({}); //用户信息

    const gamePayObj = ref<any>({
      monthAmount: 0,
      todayAmount: 0,
    }); //充值数据

    const usUserCountObj = ref<any>({
      totalCount: 0,
      monthCount: 0,
      todayCount: 0,
    }); //玩家数量统计
    const gamePeakObj = ref<any>({
      maxPeak: 0,
      monthMaxPeak: 0,
      TodayMaxPeak: 0,
    }); //游戏峰值统计

    const playCountObj = ref<any>({
      monthCount: 0,
      todayCount: 0,
    }); //游戏人数

    const beansObj = ref<any>({
      totalAmount: 0,
      totalUserCount: 0,
      monthTotalAmount: 0,
      monthIncAmount: 0,
      monthDecAmount: 0,
      todayTotalAmount: 0,
      todayIncAmount: 0,
      todayDecAmount: 0,
    }); //钻石汇总数据

    const goldObj = ref<any>({
      totalAmount: 0,
      totalUserCount: 0,
      monthTotalAmount: 0,
      monthIncAmount: 0,
      monthDecAmount: 0,
      todayTotalAmount: 0,
      todayIncAmount: 0,
      todayDecAmount: 0,
    }); //金币汇总数据

    const onlineDataObj = ref<any>({
      nextDayOnlineUserCount: 0,
      day3OnlineUserCount: 0,
      day7OnlineUserCount: 0,
    });

    //日游戏人数
    const gameCountTabList = [
      {
        key: "1",
        tab: "日游戏人数汇总",
      },
      {
        key: "2",
        tab: "海南麻将私人场",
      },
      {
        key: "3",
        tab: "海南麻将俱乐部",
      },
      // {
      //   key: "4",
      //   tab: "海南麻将金币场",
      // },
    ];
    const activeGameCountKey = ref("1");
    const handleGameCountKeyChange = (key: any) => {
      activeGameCountKey.value = key;
      renderGamePlayCountChart();
    };

    //由socketio传递过来的数据
    const homeData: any = inject("homeData");
    const homeGameCountChartData: any = inject("homeGameCountChartData");
    //数据变更后重新渲染图表
    watch(homeGameCountChartData, val => {
      renderOnlineCharts(val);
    });
    //在线人数/游戏人数图表数据
    let gameCountChartPlot: Line | undefined;

    //按天统计的注册人数图表数据
    const dayRegCountChart = ref<any>([]);
    const monthRegCountChart = ref<any>([]);
    const dayRegSearchData = reactive({
      startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
      stType: 1, //1按天  2按月
    });
    //注册人数图表
    let regCountChartPlot: Area | undefined;

    //按天统计的游戏人数图表数据
    const dayPlayCountChartData = ref<any>([]);
    const dayPeakData = ref<any>([]);
    const dayPlayCountSearchData = reactive({
      startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
      stType: 1, //1按天  2按月
    });
    //注册人数图表
    let playCountChartPlot: Line | undefined;

    //各游戏人数
    const gamePlayCountChartData = ref<any>([]); //游戏人数数据
    const gamePlayCountSearchData = reactive({
      startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
    });
    //个游戏人数图表
    let gamePlayCountChartPlot: Line | undefined;

    //获取用户信息
    const getUserinfo = async () => {
      const { data } = await http.get("session/detail");
      userinfo.value = data.data;
    };

    //获取汇总统计信息
    const getStatistic = async () => {
      const { data } = await http.get("home/statistic");
      usUserCountObj.value = data.usUserCountObj;
      gamePeakObj.value = data.gamePeakObj;
      playCountObj.value = data.playCountObj;
      beansObj.value = data.beansObj;
      goldObj.value = data.goldObj;
      onlineDataObj.value = data.onlineDataObj;
      gamePayObj.value = data.gamePayObj;
    };

    //获取当前游戏人数
    const getCurrPlayCount = async () => {
      const { data } = await http.get("home/curr_play_count");
      homeData.value.currPlayCount = data.currPlayCount;
    };

    //获取在线人数图表数据
    const getGameCountChartData = async () => {
      const { data } = await http.get("home/game_count_chart_data");
      homeGameCountChartData.value = data.data;

      // //渲染图表
      // renderOnlineCharts(homeGameCountChartData.value);
    };
    //渲染在线人数
    const renderOnlineCharts = (data: any) => {
      const rdata: any = [];
      data.forEach((item: any) => {
        rdata.push({
          time: item.time,
          name: "在线人数",
          value: item.onlineCount,
        });
        rdata.push({
          time: item.time,
          name: "游戏人数",
          value: item.playCount,
        });
      });

      if (gameCountChartPlot) {
        gameCountChartPlot?.changeData(rdata);
      } else {
        gameCountChartPlot = new Line("home-charts-online", {
          data: rdata,
          height: 200,
          xField: "time",
          yField: "value",
          seriesField: "name",
          // yAxis: {
          //   label: {
          //     formatter: (v: any) => `${(v / 10e8).toFixed(1)} B`,
          //   },
          // },
          legend: {
            position: "top",
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: "path-in",
              duration: 1500,
            },
          },
        });

        gameCountChartPlot.render();
      }
    };

    //获取新增玩家图表数据
    const getRegCountChartData = async () => {
      const { data } = await http.get("home/reg_chart_data", dayRegSearchData);
      dayRegCountChart.value = data.dayData;
      monthRegCountChart.value = data.monthData;

      renderNewPlayerCharts();
    };
    //渲染新增玩家图表
    const renderNewPlayerCharts = () => {
      let data = dayRegCountChart.value;
      if (dayRegSearchData.stType == 2) {
        //按月
        data = monthRegCountChart.value;
      }
      if (regCountChartPlot) {
        regCountChartPlot?.changeData(data);
      } else {
        regCountChartPlot = new Area("home-charts-player", {
          data: data,
          xField: "time",
          yField: "count",
          height: 200,
          // label: {
          //   // 可手动配置 label 数据标签位置
          //   position: "middle", // 'top', 'bottom', 'middle',
          //   // 配置样式
          //   style: {
          //     fill: "#FFFFFF",
          //     opacity: 0.6,
          //   },
          // },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          meta: {
            count: {
              alias: "新增人数",
            },
          },
        });

        regCountChartPlot.render();
      }
    };

    //获取宽度
    const mediaType = inject<Ref<string>>("mediaType");
    const getCardWidth = () => {
      if (mediaType!.value == "lg") {
        return "33.33%";
      } else {
        return "100%";
      }
    };

    //获取在线人数表
    const getPlayCountChartData = async () => {
      const { data } = await http.get("home/play_count_chart_data", dayPlayCountSearchData);
      dayPlayCountChartData.value = data.dayPlayCountData;
      dayPeakData.value = data.dayPeakData;

      renderPlayCountCharts();
    };
    //渲染游戏人数图表
    const renderPlayCountCharts = () => {
      // let data = dayPlayCountChartData.value;
      // if (dayRegSearchData.stType == 2) {
      //   //按月
      //   data = monthRegCountChart.value;
      // }

      //格式化数据
      const dateList: any = [];
      const data: any = [];
      //先找到所有的时间
      dayPlayCountChartData.value.forEach((item: any) => {
        dateList.push(item.time);
      });
      dayPeakData.value.forEach((item: any) => {
        const index = dateList.findIndex((itm: any) => {
          return itm == item.time;
        });
        if (index === -1) {
          dateList.push(item.time);
        }
      });
      //按时间归类数据
      dateList.forEach((item: any) => {
        let pcCount = 0;
        const pcItem = dayPlayCountChartData.value.find((itm: any) => {
          return itm.time == item;
        });
        if (pcItem) {
          pcCount = pcItem.count;
        }
        data.push({
          time: item,
          count: pcCount,
          name: "游戏人数",
        });

        let pkCount = 0;
        const pkItem = dayPeakData.value.find((itm: any) => {
          return itm.time == item;
        });
        if (pkItem) {
          pkCount = pkItem.count;
        }
        data.push({
          time: item,
          count: pkCount,
          name: "在线峰值",
        });
      });

      if (playCountChartPlot) {
        playCountChartPlot?.changeData(data);
      } else {
        playCountChartPlot = new Line("home-charts-play-count", {
          data: data,
          xField: "time",
          yField: "count",
          seriesField: "name",
          height: 200,
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          legend: {
            position: "top",
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: "path-in",
              duration: 1500,
            },
          },
        });

        playCountChartPlot.render();
      }
    };

    //获取游戏人数
    const getGamePlayCountChartData = async () => {
      const { data } = await http.get("home/play_game_play_count_chart_data", gamePlayCountSearchData);
      gamePlayCountChartData.value = data.data;

      renderGamePlayCountChart();
    };
    const renderGamePlayCountChart = () => {
      const rdata: any = [];
      let timeKey = "stDate";
      gamePlayCountChartData.value.forEach((item: any) => {
        const time = item[timeKey].replaceAll("-", "/");
        if (activeGameCountKey.value == "1") {
          rdata.push({
            time: time,
            name: "总游戏人数",
            value: item.hnmjTotalUserCount,
          });
          rdata.push({
            time: time,
            name: "海南麻将私人场",
            value: item.hnmjUserCount,
          });
          rdata.push({
            time: time,
            name: "海南麻将俱乐部",
            value: item.clubHnmjUserCount,
          });
          // rdata.push({
          //   time: time,
          //   name: "海南麻将金币场",
          //   value: item.hnmjGoldUserCount,
          // });
        } else if (activeGameCountKey.value == "2") {
          rdata.push({
            time: time,
            name: "二人",
            value: item.hnmj2UserCount,
          });
          rdata.push({
            time: time,
            name: "三人",
            value: item.hnmj3UserCount,
          });
          rdata.push({
            time: time,
            name: "四人",
            value: item.hnmj4UserCount,
          });
        } else if (activeGameCountKey.value == "3") {
          rdata.push({
            time: time,
            name: "二人",
            value: item.clubHnmj2UserCount,
          });
          rdata.push({
            time: time,
            name: "三人",
            value: item.clubHnmj3UserCount,
          });
          rdata.push({
            time: time,
            name: "四人",
            value: item.clubHnmj4UserCount,
          });
        }
        // else if (activeGameCountKey.value == "4") {
        //   rdata.push({
        //     time: time,
        //     name: "二人",
        //     value: item.clubHnmj2UserCount,
        //   });
        //   rdata.push({
        //     time: time,
        //     name: "三人",
        //     value: item.clubHnmj3UserCount,
        //   });
        //   rdata.push({
        //     time: time,
        //     name: "四人",
        //     value: item.clubHnmj4UserCount,
        //   });
        // }
      });

      if (gamePlayCountChartPlot) {
        gamePlayCountChartPlot?.changeData(rdata);
      } else {
        gamePlayCountChartPlot = new Line("home-charts-gmcount-main", {
          data: rdata,
          height: 200,
          xField: "time",
          yField: "value",
          seriesField: "name",
          // yAxis: {
          //   label: {
          //     formatter: (v: any) => `${(v / 10e8).toFixed(1)} B`,
          //   },
          // },
          legend: {
            position: "top",
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: "path-in",
              duration: 1500,
            },
          },
        });

        gamePlayCountChartPlot.render();
      }
    };

    onMounted(() => {
      renderPayCharts();

      getUserinfo();
      getStatistic();
      getCurrPlayCount();
      getGameCountChartData();
      getRegCountChartData();
      getPlayCountChartData();
      getGamePlayCountChartData();
    });

    return {
      userinfo,
      gamePayObj,
      usUserCountObj,
      gamePeakObj,
      playCountObj,
      homeData,
      dayRegSearchData,
      dayPlayCountSearchData,
      beansObj,
      goldObj,
      gameCountTabList,
      activeGameCountKey,
      gamePlayCountSearchData,
      onlineDataObj,

      getRegCountChartData,
      renderNewPlayerCharts,
      getPlayCountChartData,
      handleGameCountKeyChange,
      getGamePlayCountChartData,
      getCardWidth,
    };
  },
});
</script>

<style lang="scss" scoped>
.l-home-header {
  background: #fff;
  margin: 0 0 15px 0;
  padding: 10px 15px;
  padding-bottom: 15px;

  .l-home-header-title {
    font-size: 18px;
    font-weight: bold;
  }
}

.l-home-static-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-home-hello {
  font-size: 18px;
  margin-bottom: 5px;
}

.l-home-sec-text {
  color: rgba(0, 0, 0, 0.45);
}

.l-home-st-icon {
  font-size: 30px;
}

.home-charts-container {
  height: 200px;
}

.l-home-static-ch-font {
  font-size: 15px;
}

.l-home-static-container {
  padding: 0 13px;
}
</style>
